import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import LoadingSpinner from "./LoadingSpinner";
import useFormValidation from "../hooks/useFormValidation";
import { getAgent, updateAgent, deleteAgent } from "../services/api";

const validateAgent = (values) => {
  let errors = {};
  // Add validation rules similar to CreateAgent component
  return errors;
};

const AgentDetails = () => {
  const [agent, setAgent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setValues,
    setIsSubmitting,
  } = useFormValidation(agent || {}, validateAgent);

  useEffect(() => {
    fetchAgent();
  }, [id]);

  useEffect(() => {
    if (agent) {
      setValues(agent);
    }
  }, [agent]);

  const fetchAgent = async () => {
    try {
      const data = await getAgent(id);
      setAgent(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching agent:", error);
      setError("Failed to fetch agent details. Please try again.");
      setLoading(false);
    }
  };

  const handleUpdateAgent = async () => {
    try {
      await updateAgent(id, values);
      setIsEditing(false);
      setAgent(values);
    } catch (error) {
      console.error("Error updating agent:", error);
      setError("Failed to update agent. Please try again.");
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this agent?")) {
      try {
        await deleteAgent(id);
        navigate("/dashboard");
      } catch (error) {
        console.error("Error deleting agent:", error);
        setError("Failed to delete agent. Please try again.");
      }
    }
  };

  React.useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      handleUpdateAgent();
    }
  }, [isSubmitting, errors]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!agent) return <ErrorMessage message="Agent not found" />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 my-6">
        Agent Details
      </h1>
      {isEditing ? (
        <form onSubmit={handleSubmit} className="space-y-6">
          {Object.keys(agent).map((key) => (
            <div key={key}>
              <label
                htmlFor={key}
                className="block text-sm font-medium text-gray-700"
              >
                {key.replace("_", " ").charAt(0).toUpperCase() +
                  key.replace("_", " ").slice(1)}
              </label>
              <input
                type="text"
                name={key}
                id={key}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={values[key] || ""}
                onChange={handleChange}
                disabled={["id", "owner_id", "agent_id"].includes(key)}
              />
              {errors[key] && <ErrorMessage message={errors[key]} />}
            </div>
          ))}
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </form>
      ) : (
        <div>
          {Object.entries(agent).map(([key, value]) => (
            <p key={key} className="my-2">
              <strong>
                {key.replace("_", " ").charAt(0).toUpperCase() +
                  key.replace("_", " ").slice(1)}
                :
              </strong>{" "}
              {value}
            </p>
          ))}
          <button
            onClick={() => setIsEditing(true)}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit
          </button>
          <button
            onClick={handleDelete}
            className="mt-4 ml-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default AgentDetails;
