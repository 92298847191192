import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-100 to-white flex flex-col">
      <header className="bg-indigo-600 shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <div className="flex items-center">
              <img
                src="/logo.svg"
                alt="VoxBridge Logo"
                className="h-8 w-auto mr-2"
              />
              <h1 className="text-white text-2xl font-bold">VoxBridge</h1>
            </div>
            <nav className="flex space-x-4">
              <Link
                to="/signup"
                className="text-white hover:bg-indigo-500 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out"
              >
                Sign Up
              </Link>
              <Link
                to="/signin"
                className="bg-white text-indigo-600 hover:bg-indigo-50 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out"
              >
                Sign In
              </Link>
            </nav>
          </div>
        </div>
      </header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Welcome to <span className="text-indigo-600">VoxBridge</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Create, manage, and scale your REST API voice agents with ease.
              Empower your business with intelligent voice solutions.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/signup"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Get Started
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="https://youtu.be/sxSB_fk0OPY"
                  target="_blank"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Watch Demo
                </a>
              </div>
            </div>
          </div>
        </div>

        <section className="bg-gray-50 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="text-3xl font-bold text-gray-900 text-center mb-8">
              Key Features
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  title: "Easy Integration",
                  description:
                    "Seamlessly integrate with your existing REST APIs in minutes.",
                  icon: "🔌",
                },
                {
                  title: "Customizable Agents",
                  description:
                    "Create voice agents tailored to your specific business needs.",
                  icon: "🎨",
                },
                {
                  title: "Real-Time Monitoring",
                  description:
                    "Monitor and manage your agents with powerful analytics.",
                  icon: "📊",
                },
                {
                  title: "Multi-Language Support",
                  description:
                    "Communicate with customers in their preferred language.",
                  icon: "🌐",
                },
                {
                  title: "Scalable Infrastructure",
                  description:
                    "Easily scale your voice operations as your business grows.",
                  icon: "🚀",
                },
                {
                  title: "Secure & Compliant",
                  description:
                    "Bank-grade security and compliance with industry standards.",
                  icon: "🔒",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-150 ease-in-out"
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h4 className="text-xl font-semibold text-gray-900 mb-2">
                    {feature.title}
                  </h4>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="text-3xl font-bold text-gray-900 text-center mb-8">
              What Our Customers Say
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                {
                  quote:
                    "VoxBridge has transformed the way we handle customer interactions. It's intuitive and powerful!",
                  author: "Alex Johnson",
                  role: "CEO of TechCorp",
                },
                {
                  quote:
                    "The ease of integration and customization is unparalleled. Highly recommended!",
                  author: "Maria Lopez",
                  role: "CTO of InnovateX",
                },
              ].map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-150 ease-in-out"
                >
                  <p className="text-gray-600 italic mb-4">
                    "{testimonial.quote}"
                  </p>
                  <div className="flex items-center">
                    <img
                      src={`/avatars/avatar-${index + 1}.jpg`}
                      alt={testimonial.author}
                      className="h-12 w-12 rounded-full mr-4"
                    />
                    <div>
                      <p className="text-gray-900 font-semibold">
                        {testimonial.author}
                      </p>
                      <p className="text-gray-600">{testimonial.role}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex justify-center md:order-2 space-x-6">
              <Link to="/about" className="text-gray-400 hover:text-gray-300">
                About
              </Link>
              <Link to="/contact" className="text-gray-400 hover:text-gray-300">
                Contact
              </Link>
              <a
                href=""
                className="text-gray-400 hover:text-gray-300"
              >
                Twitter
              </a>
              <a
                href=""
                className="text-gray-400 hover:text-gray-300"
              >
                LinkedIn
              </a>
            </div>
            <div className="mt-8 md:mt-0 md:order-1">
              <p className="text-center text-base text-gray-400">
                &copy; 2024 VoxBridge. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
