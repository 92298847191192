import React from "react";
import { useNavigate } from "react-router-dom";
import useFormValidation from "../hooks/useFormValidation";
import { createAgent } from "../services/api";
import ErrorMessage from "./ErrorMessage";

const initialState = {
  twilio_phone_number: "",
  rest_api_url: "",
  voice_id: "",
  welcome_text: "",
  api_key: "",
};

const validateAgent = (values) => {
  let errors = {};
  if (!values.twilio_phone_number) {
    errors.twilio_phone_number = "Twilio phone number is required";
  }
  if (!values.rest_api_url) {
    errors.rest_api_url = "REST API URL is required";
  }
  if (!values.voice_id) {
    errors.voice_id = "Voice ID is required";
  }
  if (!values.welcome_text) {
    errors.welcome_text = "Welcome text is required";
  }
  if (!values.api_key) {
    errors.api_key = "API key is required";
  }
  return errors;
};

const CreateAgent = () => {
  const navigate = useNavigate();
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setIsSubmitting,
  } = useFormValidation(initialState, validateAgent);

  const handleCreateAgent = async () => {
    try {
      await createAgent(values);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating agent:", error);
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      handleCreateAgent();
    }
  }, [isSubmitting, errors]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 my-6">
        Create New Agent
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        {Object.keys(initialState).map((key) => (
          <div key={key}>
            <label
              htmlFor={key}
              className="block text-sm font-medium text-gray-700"
            >
              {key.replace("_", " ").charAt(0).toUpperCase() +
                key.replace("_", " ").slice(1)}
            </label>
            <input
              type="text"
              name={key}
              id={key}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={values[key]}
              onChange={handleChange}
            />
            {errors[key] && <ErrorMessage message={errors[key]} />}
          </div>
        ))}
        <button
          type="submit"
          disabled={isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Agent
        </button>
      </form>
    </div>
  );
};

export default CreateAgent;
