import axios from "axios";

//const API_URL = "http://localhost:8080";
const API_URL = "https://voxbridge.tflames.com/api";

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const login = async (email, password) => {
  const response = await api.post(
    "/token",
    new URLSearchParams({ username: email, password }),
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
  return response.data;
};

export const signup = async (email, password) => {
  const response = await api.post("/signup", { email, password });
  return response.data;
};

export const getAgents = async () => {
  const response = await api.get("/agents");
  return response.data;
};

export const createAgent = async (agentData) => {
  const response = await api.post("/agents", agentData);
  return response.data;
};

export const getAgent = async (id) => {
  const response = await api.get(`/agents/${id}`);
  return response.data;
};

export const updateAgent = async (id, agentData) => {
  const response = await api.put(`/agents/${id}`, agentData);
  return response.data;
};

export const deleteAgent = async (id) => {
  const response = await api.delete(`/agents/${id}`);
  return response.data;
};
